<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
       {{$t('chartsAdvance.Alertmsg')}}
      </div>
    </b-alert>

    <div
    class="card card-custom bg-gray-100 gutter-b card-stretch card-shadowless"
  >
    <!--begin::Header-->
    <div class="card-header h-auto border-0">
      <!--begin::Title-->
      <div class="card-title py-5">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder">
            {{$t('chartsAdvance.EnergyUsedInkw')}}
            </span>
          <span class="d-block text-dark-50 mt-2 font-size-sm">
            {{$t('chartsAdvance.Deviceswithmoreenergyused')}}
            </span>
        </h3>
      </div>
      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'month' }"
              href="#kt_charts_widget_2_chart_tab_1"
              @click="show = 'month'"
            >
              <span class="nav-text font-size-sm">
                {{$t('chartsAdvance.Months12')}}
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'week' }"
              href="#kt_charts_widget_2_chart_tab_2"
              @click="show = 'week'"
            >
              <span class="nav-text font-size-sm">
                {{$t('chartsAdvance.Weeks4')}}
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'day' }"
              href="#kt_charts_widget_2_chart_tab_3"
              @click="show = 'day'"
            >
              <span class="nav-text font-size-sm">
                {{$t('chartsAdvance.Days7')}}
                </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'hour' }"
              href="#kt_charts_widget_2_chart_tab_3"
              @click="show = 'hour'"
            >
              <span class="nav-text font-size-sm">
                {{$t('chartsAdvance.hours24')}}
                </span>
            </a>
          </li>
        </ul>
      </div>
      <!--end::Toolbar-->
    </div>
      <div class="card-body">
        <!--begin::Example-->
        <apexchart
        class="card-rounded-bottom"
        :options="chartOptions"
        :series="dataToShow"
        type="line"
      ></apexchart>
        <!--end::Example-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      show: "day",
      chartOptions: {},
      month: [
        {
          name: "Net Profit",
          data: [44, 55, 57, 34, 61, 101]
        },
        {
          name: "Revenue",
          data: [76, 34, 56, 15, 87, 105]
        }
      ],
      day: [
        {
          name: "Net Profit",
          data: [44, 34, 57, 21, 61, 58]
        },
        {
          name: "Revenue",
          data: [20, 85, 30, 34, 87, 105]
        }
      ],
      week: [
        {
          name: "Net Profit",
          data: [44, 34, 57, 56, 61, 44]
        },
        {
          name: "Revenue",
          data: [76, 55, 101, 98, 34, 105]
        }
      ]
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    dataToShow() {
      if (this.show === "month") return this.month;
      if (this.show === "week") return this.week;
      if (this.show === "day") return this.day;
      return this.day;
    }
  },
  components: {
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Alerts" }
    ]);
    this.chartOptions = 
      {
          series: [{
          name: 'Sales',
          data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5]
        }],
          chart: {
          height: 350,
          type: 'line',
        },
        forecastDataPoints: {
          count: 7
        },
        stroke: {
          width: 5,
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: ['1/11/2000', '2/11/2000', '3/11/2000', '4/11/2000', '5/11/2000', '6/11/2000', '7/11/2000', '8/11/2000', '9/11/2000', '10/11/2000', '11/11/2000', '12/11/2000', '1/11/2001', '2/11/2001', '3/11/2001','4/11/2001' ,'5/11/2001' ,'6/11/2001'],
          tickAmount: 10,
        },
        title: {
          text: 'Forecast',
          align: 'left',
          style: {
            fontSize: "16px",
            color: '#666'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: [ '#FDD835'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          },
        },
        yaxis: {
          min: -10,
          max: 40
        }
        };
    
  }
};
</script>
